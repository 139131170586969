import React, { Fragment, useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { PhoneIcon, MailIcon } from '@heroicons/react/outline';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const partners = [
  {
    id: 1,
    img: require('./images/eurofit.png'),
  },
  {
    id: 2,
    img: require('./images/yogakids.png'),
  },
  {
    id: 3,
    img: require('./images/euromusic.png'),
  },
  {
    id: 4,
    img: require('./images/mindful.png'),
  },
  {
    id: 5,
    img: require('./images/euphonics.png'),
  },
  {
    id: 6,
    img: require('./images/mathlab.png'),
  },
  {
    id: 7,
    img: require('./images/scientificspark.png'),
  },
];

function Mobile() {
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('popup') !== 'true') {
      setOpen(true);
      sessionStorage.setItem('popup', 'true');
    }
  }, []);

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    const pattern = /^[6-9][0-9]{6,9}$/;
    if (!pattern.test(phone)) {
      alert('Please enter a valid phone number');
    }

    if (phone !== '') {
      setOpen(false);
      await axios
        .post('https://eurokids-1aci.onrender.com/popup', {
          mobile: phone,
        })
        .then((res) => {
          // navigate('/thank-you');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const Popup = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-900 bg-opacity-75' />
            </Transition.Child>

            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                  <div className='mt-3 text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-[#21409a] text-center'
                    >
                      Enroll your child
                    </Dialog.Title>
                    <div className='mt-2'>
                      <form onSubmit={(e) => onSubmit(e)}>
                        <div>
                          <input
                            className='border h-10 outline-none px-3 rounded-md w-full focus:ring-[#21409a] focus:border-[#21409a]'
                            type='text'
                            placeholder='Mobile Number'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            maxLength='10'
                          />
                        </div>
                        <div class='flex items-center mt-3'>
                          <input
                            id='disclaimer'
                            name='disclaimer'
                            type='checkbox'
                            class='h-4 w-4 rounded'
                          />
                          <div class='ml-2 text-sm'>
                            <label
                              for='disclaimer'
                              class='font-medium text-gray-700'
                            >
                              I agree to the Privacy Policy and Terms of
                              Service.
                            </label>
                          </div>
                        </div>
                        <button
                          type='submit'
                          className='w-full rounded border border-transparent shadow-sm px-4 py-2 bg-[#21409a] text-base font-semibold mt-3 text-white hover:bg-red-700 sm:text-sm'
                        >
                          Submit Now
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      {/* <Header /> */}
      <Popup />
      <img
        src={require('./images/Ek_Banners_Mobile.jpg')}
        alt='Ek Banners Mobile'
      />
      <FormComponent />
      <ChildhoodEducation />
      <img
        src={require('./images/1.png')}
        className='w-full'
        alt='1'
        id='programs'
      />
      <EuroKids />
      <img
        src={require('./images/2.png')}
        className='w-full'
        alt='2'
        id='eunoia'
      />
      <Partners />
      <img src={require('./images/3.png')} className='w-full' alt='3' />
      <Awards />
      <img src={require('./images/4.png')} className='w-full' alt='4' />
      {/* <Testimonials /> */}
      <img src={require('./images/5.png')} className='w-full' alt='5' />
      <Footer id='contactUs' />
    </>
  );
}

const ChildhoodEducation = () => {
  return (
    <div className='bg-[#E6FEF0] pt-8 px-5'>
      <p className='text-center font-bold text-xl text-[#21409A] mb-7'>
        Is Early Childhood Education important?
      </p>
      <div className='bg-[#f1f6ff] p-5 space-y-5 font-bold'>
        <p className='text-gray-600'>
          90% of brain development happens before the age of 6. Hence, early
          childhood education is the key to a flying start for every child.
        </p>
        <img
          src={require('./images/brain_development.png')}
          alt='Brain Development'
        />
      </div>
      <h6 className='py-8 font-bold text-center text-gray-600'>
        Here’s what your child could miss out without early schooling at the
        right age.
      </h6>
      <div className='grid grid-cols-2'>
        <div className='mb-4'>
          <img
            src={require('./images/feature_1.png')}
            className='mx-auto w-14 h-14'
            alt='Social'
          />
          <p className='text-center'>Improved social skills</p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_2.png')}
            alt='Social'
            className='mx-auto w-14 h-14'
          />
          <p className='text-center'>
            No need for special education instruction
          </p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_3.png')}
            alt='Social'
            className='mx-auto w-14 h-14'
          />
          <p className='text-center'>
            Better marks and enhanced attention spans
          </p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_4.png')}
            alt='Social'
            className='mx-auto w-14 h-14'
          />
          <p className='text-center'>Fewer behavioural problems</p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_5.png')}
            alt='Social'
            className='mx-auto h-14 w-14'
          />
          <p className='text-center'>Development milestones achieved</p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_6.png')}
            alt='Social'
            className='mx-auto w-14 h-14'
          />
          <p className='text-center'>Better language development</p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_7.png')}
            alt='Social'
            className='mx-auto w-14 h-14'
          />
          <p className='text-center'>Reduced discipline issues</p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_8.png')}
            alt='Social'
            className='mx-auto w-14 h-14'
          />
          <p className='text-center'>Increased social interactions</p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_9.png')}
            alt='Social'
            className='mx-auto w-14 h-14'
          />
          <p className='text-center'>Better life success</p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_10.png')}
            alt='Social'
            className='mx-auto w-14 h-14'
          />
          <p className='text-center'>Getting highly skilled jobs</p>
        </div>
        <div className='mb-4'>
          <img
            src={require('./images/feature_11.png')}
            alt='Social'
            className='mx-auto w-14 h-14'
          />
          <p className='text-center'>
            4 times more likely to graduate with high grades
          </p>
        </div>
      </div>
    </div>
  );
};

const EuroKids = () => {
  return (
    <div className='bg-[#D8FCE9] space-y-4'>
      <p className='text-center  font-bold text-[#21409A]'>
        Choose the right EuroKids programs to get started online
      </p>
      {/* <img
        src={require("./images/kid.png")}
        alt="kid"
        className="h-56 mx-auto"
      />
      <p className="text-xl text-center font-semibold text-[#21409A]">
        15 months Integrated Programme
      </p>
      <p className="font-bold text-center text-gray-600">
        Enables preparation for the next academic year
      </p>
      <p className="font-bold text-center text-gray-600">
        Special Settling Curriculum to pick up pace with the previous academic
        year
      </p>
      <p className="font-bold text-center text-gray-600">
        Program Levels: Integrated Nursery | EuroJunior | EuroSenior
      </p>
      <p className="font-bold text-center text-gray-600">Age: 3 to 6 years</p> */}

      <MyTabs />
    </div>
  );
};

const MyTabs = () => {
  return (
    <div>
      <Tab.Group>
        <Tab.List className='flex justify-center'>
          <Tab
            className={({ selected }) =>
              selected
                ? 'border-b-4 pb-3 px-3 text-[#21409A] border-[#21409A]'
                : 'text-black border-b-4 px-3 pb-3 border-gray-300'
            }
          >
            <img
              src={require('./images/playgroup.png')}
              className='h-10 mx-auto'
              alt='playgroup'
            />
            <p className='text-sm'>Playgroup</p>
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? 'border-b-4 pb-3 px-3 text-[#21409A] border-[#21409A]'
                : ' text-black border-b-4 pb-3 px-3 border-gray-300'
            }
          >
            <img
              src={require('./images/nursery.png')}
              className='h-10 mx-auto'
              alt='nursery'
            />
            <p className='text-sm'>Nursery</p>
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? 'border-b-4 pb-3 px-3 text-[#21409A] border-[#21409A]'
                : ' text-black border-b-4 pb-3 px-3 border-gray-300'
            }
          >
            <img
              src={require('./images/euro_junior.png')}
              className='h-10 mx-auto'
              alt='euro_junior'
            />
            <p className='text-sm'>EuroJunior</p>
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? 'border-b-4 pb-3 px-3 text-[#21409A] border-[#21409A]'
                : ' text-black border-b-4 pb-3 px-3 border-gray-300'
            }
          >
            <img
              src={require('./images/euro_senior.png')}
              className='h-10 mx-auto'
              alt='euro_senior'
            />
            <p className='text-sm'>EuroSenior</p>
          </Tab>
        </Tab.List>
        <Tab.Panels>
          {/* Playgroup */}
          <Tab.Panel>
            <div className='p-5'>
              <p className='font-semibold text-center text-gray-500'>
                2 - 3.5 Years :
              </p>
              <p className='text-xl text-center text-[#21409A] font-bold'>
                Playgroup
              </p>
            </div>
            <Swiper
              navigation={true}
              loop={true}
              modules={[Navigation]}
              className='p-8 mySwiper'
            >
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md p-5 h-48 text-white text-lg mx-auto max-w-[200px]'>
                  <p>
                    Hands-on discovery and exploration with exclusive EuroKids
                    kits
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Developing language skills</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Encouraging learning of new age skills</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Engaging children physically, emotionally and socially</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Shaping scientific thought in children</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </Tab.Panel>

          {/* Nursery */}
          <Tab.Panel>
            <div className='p-5'>
              <p className='font-semibold text-center text-gray-500'>
                3 - 4.5 Years :
              </p>
              <p className='text-xl text-center text-[#21409A] font-bold'>
                Playgroup
              </p>
            </div>
            <Swiper
              navigation={true}
              loop={true}
              modules={[Navigation]}
              className='p-8 mySwiper'
            >
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md p-5 h-48 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Imparts essential life skills</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>
                    Enhances learning and interaction through fun with exclusive
                    EuroKids kits
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Cultivates creativity & Develops curiosity</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Improves imagination through Art and Music</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </Tab.Panel>

          {/* Euro Junior */}
          <Tab.Panel>
            <div className='p-5'>
              <p className='font-semibold text-center text-gray-500'>
                4 - 5.5 Years :
              </p>
              <p className='text-xl text-center text-[#21409A] font-bold'>
                Playgroup
              </p>
            </div>
            <Swiper
              navigation={true}
              loop={true}
              modules={[Navigation]}
              className='p-8 mySwiper'
            >
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md p-5 h-48 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Special activities for developing language skills</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>
                    Age appropriate learning with exclusive EuroKids learning
                    tools
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-56 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>
                    Encourages children to express their ideas, thoughts and
                    feelings Allows children to grow at their own pace
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </Tab.Panel>

          {/* Euro Senior */}
          <Tab.Panel>
            <div className='p-5'>
              <p className='font-semibold text-center text-gray-500'>
                5 - 6.5 Years :
              </p>
              <p className='text-xl text-center text-[#21409A] font-bold'>
                Playgroup
              </p>
            </div>
            <Swiper
              navigation={true}
              loop={true}
              modules={[Navigation]}
              className='p-8 mySwiper'
            >
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md p-5 h-48 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Building & enhancing vocabulary</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Developing attention span</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>
                    Innovative methods to enhance reading & writing with
                    exclusive EuroKids Kits
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='relative overflow-hidden'>
                  <img
                    src={require('./images/rectangle.png')}
                    alt='bars_tabs'
                    className='mx-auto'
                  />
                </div>
                <div className='bg-[#21409A] rounded-md h-48 p-5 text-white text-lg mx-auto max-w-[200px]'>
                  <p>Preparing children for primary school</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

const Partners = () => {
  return (
    <div className='bg-[#FECA02] p-5'>
      <img
        src={require('./images/eunoia.png')}
        alt='eunoia'
        className='w-auto h-12'
      />
      <p className='mt-3 text-lg text-gray-700'>
        At the heart of our preschooling child-first ideology is Eunoia - a
        mindful curriculum that enables holistic development of your child’s
        mind, body and soul
      </p>
      <div className='grid grid-cols-2 gap-2'>
        {partners.map((item) => (
          <div className='p-5 bg-white rounded' key={item.id}>
            <img src={item.img} alt={item.id} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Awards = () => {
  return (
    <div className='bg-[#545FAD]'>
      <div>
        <p className='pb-5 text-xl font-bold text-center text-white'>
          India’s Most Awarded Pre-School Group
        </p>
        <Swiper
          navigation={true}
          loop={true}
          modules={[Navigation]}
          className='mx-5 mySwiper'
        >
          <SwiperSlide className='m-auto'>
            <img
              src={require('./images/Awards/best-educations-brand.jpg')}
              alt='best-educations-brand'
              className='mx-auto'
            />
          </SwiperSlide>
          <SwiperSlide className='m-auto'>
            <img
              src={require('./images/Awards/bw-education.jpg')}
              alt='bw-education'
              className='mx-auto'
            />
          </SwiperSlide>
          <SwiperSlide className='m-auto'>
            <img
              src={require('./images/Awards/great-place-to-work.jpg')}
              alt='great-place-to-work'
              className='mx-auto'
            />
          </SwiperSlide>
          <SwiperSlide className='m-auto'>
            <img
              src={require('./images/Awards/power-of-trust.jpg')}
              alt='power-of-trust'
              className='mx-auto'
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

// const Testimonials = () => {
//   return (
//     <div className='bg-[#D6FDE9]' id='testimonial'>
//       <p className='mx-auto text-center text-[#545FAD] text-xl font-bold max-w-[300px]'>
//         Parents across India trust EuroKids Pre-School Expertise
//       </p>
//       <Swiper
//         navigation={true}
//         loop={true}
//         modules={[Navigation]}
//         className='p-8 mySwiper'
//       >
//         <SwiperSlide>
//           <div className='max-w-[300px] w-full py-8 mx-auto'>
//             <div className='p-5 bg-white rounded-tr-3xl rounded-bl-3xl'>
//               <div className='bg-[#0E40A0] h-32 w-full'></div>
//             </div>
//           </div>
//         </SwiperSlide>
//         <SwiperSlide>
//           <div className='max-w-[300px] w-full py-8 mx-auto'>
//             <div className='p-5 bg-white rounded-tr-3xl rounded-bl-3xl'>
//               <div className='bg-[#0E40A0] h-32 w-full'></div>
//             </div>
//           </div>
//         </SwiperSlide>
//         <SwiperSlide>
//           <div className='max-w-[300px] w-full py-8 mx-auto'>
//             <div className='p-5 bg-white rounded-tr-3xl rounded-bl-3xl'>
//               <div className='bg-[#0E40A0] h-32 w-full'></div>
//             </div>
//           </div>
//         </SwiperSlide>
//       </Swiper>
//     </div>
//   );
// };

const Footer = () => {
  return (
    <div className='bg-[#e6fef0]' id='contactUs'>
      <img
        src={require('./images/footer_logo.png')}
        className='mx-auto'
        alt='logo'
      />
      <div className='flex mx-5 justify-center flex-col space-y-2 mt-3 text-[#0E40A0]'>
        <a href='#programs'>Nursery</a>
        <a href='#programs'>Playgroup</a>
        <a href='#programs'>EuroJunior</a>
        <a href='#programs'>EuroSenior</a>
      </div>
      <div className='flex flex-col mx-5 mt-3 text-[#0E40A0]'>
        <a
          href='tel:7036811111'
          className='flex items-center text-xl font-bold'
        >
          <PhoneIcon className='w-4 h-4' />
          &nbsp;<span>7</span>
          &nbsp;<span>0</span>
          &nbsp;<span>3</span>
          &nbsp;<span>6</span>
          &nbsp;<span>8</span>
          &nbsp; &nbsp; &nbsp;<span>1</span>
          &nbsp;<span>1</span>
          &nbsp;<span>1</span>
          &nbsp;<span>1</span>
          &nbsp;<span>1</span>
        </a>
        <a
          href='mailto:ekhimayathnagar@gmail.com'
          className='flex items-center'
        >
          <MailIcon className='w-4 h-4' />
          &nbsp;ekhimayathnagar@gmail.com
        </a>
      </div>
      <div className='text-[#0E40A0] mt-3 mx-5'>
        3-6-451, Lane No. 5, Himayath Nagar, Hyderabad, Telangana 500029
      </div>
    </div>
  );
};

const FormComponent = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobile: Yup.string()
      .matches(/^[6-9][0-9]{6,9}$/, 'Invalid mobile number')
      .required('Mobile is required'),
    program: Yup.string()
      .required('Program is required')
      .notOneOf(['Select Program'], 'Please select a program'),
  });

  return (
    <div className='bg-[#21409A] rounded-3xl shadow-lg p-10 mx-5'>
      <p className='mb-5 font-bold text-center text-yellow-200'>
        Enroll your child with India’s Most <br /> Trusted Pre-school Brand*
      </p>
      <Formik
        initialValues={{ name: '', email: '', mobile: '', program: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await axios.post(
              'https://eurokids-1aci.onrender.com/enquiryRequest',
              values
            );
            navigate('/refer');
          } catch (error) {
            console.log(error);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className='flex flex-col items-center space-y-4'>
            <Field
              type='text'
              name='name'
              placeholder='Name'
              className='w-full p-2 rounded-lg outline-none'
            />
            <ErrorMessage
              name='name'
              component='div'
              className='text-red-500'
            />

            <Field
              type='text'
              name='mobile'
              placeholder='Mobile'
              autoComplete='off'
              maxLength={10}
              className='w-full p-2 rounded-lg outline-none'
            />
            <ErrorMessage
              name='mobile'
              component='div'
              className='text-red-500'
            />

            <Field
              type='email'
              name='email'
              placeholder='Email'
              className='w-full p-2 rounded-lg outline-none'
            />
            <ErrorMessage
              name='email'
              component='div'
              className='text-red-500'
            />

            <Field
              as='select'
              name='program'
              className='w-full p-2.5 rounded-lg outline-none'
            >
              <option value=''>Select Program</option>
              <option value='Playgroup'>Playgroup</option>
              <option value='Nursery'>Nursery</option>
              <option value='EuroJunior'>EuroJunior</option>
              <option value='EuroSenior'>EuroSenior</option>
            </Field>
            <ErrorMessage
              name='program'
              component='div'
              className='text-red-500'
            />

            <button
              type='submit'
              disabled={isSubmitting}
              className='px-4 py-2 font-bold tracking-wider text-white uppercase bg-yellow-500 rounded-xl'
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Mobile;

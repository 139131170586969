import * as firebase from 'firebase/app';
import React, { Fragment, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import { firebaseConfig } from './firebase';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import Thankyou from './screens/Thankyou';

import Hompage from './screens/Hompage';
import ReferTq from './screens/ReferTq';
import FinalTq from './screens/FinalTq';

firebase.initializeApp(firebaseConfig);

function App() {
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('popup') !== 'true') {
      setOpen(true);
      sessionStorage.setItem('popup', 'true');
    }
  }, []);

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    // Regular expression for Indian phone numbers
    const indianPhonePattern = /^[6-9][0-9]{6,9}$/;

    // Check if the entered phone number matches the Indian phone number pattern
    if (!indianPhonePattern.test(phone)) {
      alert('Please enter a valid Indian phone number');
      return;
    }

    if (phone !== '') {
      setOpen(false);
      await axios
        .post('https://eurokids-1aci.onrender.com/popup', {
          number: phone,
        })
        .then((res) => {
          setOpen(false);
          navigate('/thank-you');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const Popup = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-900 bg-opacity-75' />
            </Transition.Child>

            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                  <div className='mt-3 text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-[#21409a] text-center'
                    >
                      Enroll your child
                    </Dialog.Title>
                    <div className='mt-2'>
                      <form onSubmit={(e) => onSubmit(e)}>
                        <div>
                          <input
                            className='border h-10 outline-none px-3 rounded-md w-full focus:ring-[#21409a] focus:border-[#21409a]'
                            type='text'
                            placeholder='Mobile Number'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            maxLength='10'
                            pattern='[0-9]{10}' // Regex pattern for exactly 10 digits
                            title='Please enter a valid 10-digit phone number'
                            required
                          />
                        </div>
                        <div className='flex items-center mt-3'>
                          <input
                            id='disclaimer'
                            name='disclaimer'
                            type='checkbox'
                            className='w-4 h-4 rounded'
                            required
                          />
                          <div className='ml-2 text-sm'>
                            <label
                              for='disclaimer'
                              className='font-medium text-gray-700'
                            >
                              I agree to the Privacy Policy and Terms of
                              Service.
                            </label>
                          </div>
                        </div>
                        <button
                          type='submit'
                          className='w-full rounded border border-transparent shadow-sm px-4 py-2 bg-[#21409a] text-base font-semibold mt-3 text-white hover:bg-red-700 sm:text-sm'
                        >
                          Submit Now
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  return (
    <>
      <Header />
      <Popup />
      <Routes>
        <Route path='/' element={<Hompage />} />

        <Route path='/thank-you' element={<Thankyou />} />
        <Route path='/refer' element={<ReferTq />} />
        <Route path='/finaltq' element={<FinalTq />} />
      </Routes>
    </>
  );
}
export default App;

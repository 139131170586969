import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  ShieldCheckIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const menus = [
  {
    name: "Programs",
    description:
      "Get a better understanding of where your traffic is coming from.",
    to: "#programs",
    icon: ChartBarIcon,
  },
  {
    name: "Eunoia",
    description: "Speak directly to your customers in a more meaningful way.",
    to: "#eunoia",
    icon: CursorClickIcon,
  },
  {
    name: "Testimonial",
    description: "Your customers' data will be safe and secure.",
    to: "#testimonial",
    icon: ShieldCheckIcon,
  },
  {
    name: "Contact Us",
    description: "Connect with third-party tools that you're already using.",
    to: "#contactUs",
    icon: ViewGridIcon,
  },
];

function Header() {
  const [open, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const fixedNavbar = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", fixedNavbar);
  return (
    
    <div className={navbar
      ? "fixed top-0 z-20 w-full px-0 bg-[#21409A] shadow-2xl drop-shadow-2xl"
      : "bg-[#21409A]"}>
      <Popover className="relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center  py-4 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
              </Link>
            </div>
            <div
              className="-mr-2 -my-2 md:hidden"
              onClick={() => setOpen(true)}
            >
              <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10"> 
              {menus.map((item) => (
                <a
                  key={item.name}
                  href={item.to}
                  className="text-base font-medium text-gray-100 hover:text-gray-300 uppercase"
                >
                  {item.name}
                </a>
              ))}
            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 text-gray-100">
              <div className="block">
                <p className="font-bold tracking-wider text-lg">Himayath Nagar</p>
                <a href="tel:7036811111" className="flex items-center">
                  <PhoneIcon className="h-4 w-4" />
                  &nbsp;<span>7</span>
                  &nbsp;<span>0</span>
                  &nbsp;<span>3</span>
                  &nbsp;<span>6</span>
                  &nbsp;<span>8</span>
                  &nbsp;<span>1</span>
                  &nbsp;<span>1</span>
                  &nbsp;<span>1</span>
                  &nbsp;<span>1</span>
                  &nbsp;<span>1</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className={
              open
                ? "absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10"
                : "hidden"
            }
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-50 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={require("../images/footer_logo.png")} alt="Workflow" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button
                      onClick={() => setOpen(false)}
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6" onClick={() => setOpen(false)}>
                  <nav className="grid gap-y-8">
                    {menus.map((item) => (
                      <a
                        key={item.name}
                        href={item.to}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        <item.icon
                          className="flex-shrink-0 h-6 w-6 text-indigo-600"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {item.name}
                        </span>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}

export default Header;

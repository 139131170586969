import React from 'react'
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { ImCheckmark } from "react-icons/im";

function Thankyou() {
  return (
    <div className="text-center" style={{ margin: "112px 0" }}>
        <h1 style={{ fontSize: "130px", color: "green" }}>
          <ImCheckmark className='mx-auto' />
        </h1>
        <h4 className='pb-8'>THANK YOU!</h4>
        <p>
          Your Enquiry has been Processed Successfully. Our Executive will get
          in touch with you shortly.
        </p>
        <Link to="/" className='flex items-center justify-center text-blue-700 text-xl'>
          <BsArrowRight /> &nbsp;Back to Home
        </Link>
      </div>
  )
}

export default Thankyou
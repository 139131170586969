import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function EnrollmentForm() {
  const navigate = useNavigate();

  return (
    <div className='absolute bg-[#21409A] rounded-3xl shadow-lg p-10 top-[18%] right-[11%] form_details'>
      <p className='mb-5 font-bold text-center text-yellow-200'>
        Enroll your child with India’s Most <br /> Trusted Preschool Brand*
      </p>
      <Formik
        initialValues={{
          name: '',
          email: '',
          mobile: '',
          program: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string(),
          email: Yup.string()
            .email('Invalid email address')
            .required('This field is required*'),
          mobile: Yup.string()
            .matches(/^[6-9][0-9]{6,9}$/, 'Invalid phone number')
            .required('This field is required*'),
          program: Yup.string().required('This field is required*'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await axios.post(
              'https://eurokids-1aci.onrender.com/enquiryRequest',
              values
            );
            navigate('/refer');
          } catch (error) {
            console.error('Error:', error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className='flex flex-col items-center '>
              <div className='w-full py-2'>
                <ErrorMessage
                  name='name'
                  component='div'
                  className='mb-2 text-red-500'
                />
                <Field
                  className='w-full p-2 rounded-lg outline-none'
                  placeholder='Name'
                  autoComplete='off'
                  required
                  type='text'
                  name='name'
                />
              </div>
              <div className='w-full py-2'>
                <ErrorMessage
                  name='mobile'
                  component='div'
                  className='text-red-500'
                />
                <Field
                  className='w-full p-2 rounded-lg outline-none'
                  placeholder='Mobile'
                  autoComplete='off'
                  maxLength={10}
                  required
                  type='text'
                  name='mobile'
                />
              </div>
              <div className='w-full py-2'>
                <ErrorMessage
                  name='email'
                  component='div'
                  className='text-red-500'
                />{' '}
                <Field
                  className='w-full p-2 rounded-lg outline-none'
                  placeholder='Email'
                  autoComplete='off'
                  required
                  type='email'
                  name='email'
                />
              </div>
              <div className='w-full py-2 mb-4'>
                <ErrorMessage
                  name='program'
                  component='div'
                  className='text-red-500'
                />
                <Field
                  as='select'
                  name='program'
                  className='w-full p-2.5 rounded-lg outline-none'
                >
                  <option value=''>Select Program</option>
                  <option value='Playgroup'>Playgroup</option>
                  <option value='Nursery'>Nursery</option>
                  <option value='EuroJunior'>EuroJunior</option>
                  <option value='EuroSenior'>EuroSenior</option>
                </Field>
              </div>

              <button
                className='px-4 py-2 font-bold tracking-wider text-white uppercase bg-yellow-500 rounded-xl'
                type='submit'
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Loading...' : 'Enroll'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EnrollmentForm;

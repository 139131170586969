import React from 'react';
import banner from '../images/Eurokidsbanner.jpg';
import brainDev from '../images/brain_development.png';
import {
  // ChevronRightIcon,
  PhoneIcon,
  MailIcon,
} from '@heroicons/react/outline';
import { Tab } from '@headlessui/react';
import Form from '../components/home/Form';

const partners = [
  {
    id: 1,
    img: require('../images/eurofit.png'),
  },
  {
    id: 2,
    img: require('../images/yogakids.png'),
  },
  {
    id: 3,
    img: require('../images/euromusic.png'),
  },
  {
    id: 4,
    img: require('../images/mindful.png'),
  },
  {
    id: 5,
    img: require('../images/euphonics.png'),
  },
  {
    id: 6,
    img: require('../images/mathlab.png'),
  },
  {
    id: 7,
    img: require('../images/scientificspark.png'),
  },
];

function Home() {
  return (
    <>
      <img src={banner} alt='Home Banner' className='w-full' />
      <ChildhoodEducation />
      <div className='relative'>
        <img
          src={require('../images/rabbit_1.png')}
          alt='rabbit-1'
          className='absolute bottom-0 right-[200px] h-40 hidden xl:block rabbit_1'
        />
      </div>
      <img
        src={require('../images/1.png')}
        className='w-full'
        alt='1'
        id='programs'
      />
      <EuroKidsProgram />
      <img
        src={require('../images/2.png')}
        className='w-full'
        alt='2'
        id='eunoia'
      />
      <Partners />
      <img src={require('../images/3.png')} className='w-full' alt='3' />
      <Awarded />
      <img src={require('../images/4.png')} className='w-full' alt='4' />
      {/* <IndiaTrust /> */}
      {/* <img src={require("../images/5.png")} className="w-full" alt="5" /> */}
      <Footer id='contactUs' />
      <Form />
    </>
  );
}

const ChildhoodEducation = () => {
  return (
    <div className='bg-[#E6FEF0] pt-8'>
      <div className='mx-auto max-w-7xl'>
        <p className='text-center font-bold text-3xl text-[#21409A] mb-7'>
          Is Early Childhood Education important?
        </p>
        <div className='grid gap-8 px-5 md:grid-cols-3'>
          <div className='col-span-1 bg-[#F1F6FF] p-6 space-y-4'>
            <p className='text-gray-500'>
              90% of brain development happens before the age of 6. Hence, early
              childhood education is the key to a flying start for every child.
            </p>
            <img src={brainDev} alt='Brain Development' />
          </div>
          <div className='col-span-2 space-y-4 md:ml-9'>
            <p className='text-gray-500 max-w-[400px]'>
              Here’s what your child could miss out without early Schooling at
              the right age.
            </p>
            <div className='grid gap-5 sm:grid-cols-4'>
              <div>
                <img
                  src={require('../images/feature_1.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>Improved social skills</p>
              </div>
              <div>
                <img
                  src={require('../images/feature_2.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>
                  No need for special education instruction
                </p>
              </div>
              <div>
                <img
                  src={require('../images/feature_3.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>
                  Better marks and enhanced attention spans
                </p>
              </div>
              <div>
                <img
                  src={require('../images/feature_4.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>Fewer behavioural problems</p>
              </div>
              <div>
                <img
                  src={require('../images/feature_5.png')}
                  alt='Social'
                  className='mx-auto h-14 w-14'
                />
                <p className='text-center'>Development milestones achieved</p>
              </div>
              <div>
                <img
                  src={require('../images/feature_6.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>Better language development</p>
              </div>
              <div>
                <img
                  src={require('../images/feature_7.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>Reduced discipline issues</p>
              </div>
              <div>
                <img
                  src={require('../images/feature_8.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>Increased social interactions</p>
              </div>
              <div>
                <img
                  src={require('../images/feature_9.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>Better life success</p>
              </div>
              <div>
                <img
                  src={require('../images/feature_10.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>Getting highly skilled jobs</p>
              </div>
              <div>
                <img
                  src={require('../images/feature_11.png')}
                  alt='Social'
                  className='mx-auto w-14 h-14'
                />
                <p className='text-center'>
                  4 times more likely to graduate with high grades
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EuroKidsProgram = () => {
  return (
    <div className='bg-[#D8FCE9]'>
      <p className='text-center lg:-mt-20 text-2xl font-bold text-[#21409A] pb-8'>
        Choose the right EuroKids programs to get started online
      </p>
      {/* <div className="grid gap-4 px-6 py-8 mx-auto max-w-7xl sm:grid-cols-4">
        <div className="col-span-1">
          <img src={require("../images/kid.png")} alt="kid" />
        </div>
        <div className="col-span-3 space-y-4">
          <p className="text-2xl font-semibold text-[#21409A]">
            15 months Integrated Programme
          </p>

          <div className="flex items-center space-x-2">
            <ChevronRightIcon className="w-5 h-5 text-gray-100 bg-gray-600 rounded-full" />
            <p className="text-lg font-bold text-gray-600">
              Enables preparation for the next academic year
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <ChevronRightIcon className="w-5 h-5 text-gray-100 bg-gray-600 rounded-full" />
            <p className="text-lg font-bold text-gray-600">
              Special Settling Curriculum to pick up pace with the previous
              academic year
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <ChevronRightIcon className="w-5 h-5 text-gray-100 bg-gray-600 rounded-full" />
            <p className="text-lg font-bold text-gray-600">
              Program Levels: Integrated Nursery | EuroJunior | EuroSenior
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <ChevronRightIcon className="w-5 h-5 text-gray-100 bg-gray-600 rounded-full" />
            <p className="text-lg font-bold text-gray-600">Age: 3 to 6 years</p>
          </div>
        </div>
      </div> */}
      <MyTabs />
      <div className='relative'>
        <img
          src={require('../images/rabbit_2.png')}
          alt='rabbit 2'
          className='absolute hidden bottom-64 right-44 xl:block rabbit_2 h-72'
        />
      </div>
    </div>
  );
};

const MyTabs = () => {
  return (
    <div className='mx-auto max-w-7xl'>
      <Tab.Group>
        <Tab.List>
          <Tab
            className={({ selected }) =>
              selected
                ? 'border-b-4 pb-3 px-24 text-[#21409A] border-[#21409A]'
                : 'text-black border-b-4 px-24 pb-3 border-gray-300'
            }
          >
            <img src={require('../images/playgroup.png')} alt='playgroup' />
            <p>Playgroup</p>
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? 'border-b-4 pb-3 px-24 text-[#21409A] border-[#21409A]'
                : ' text-black border-b-4 px-24 pb-3 border-gray-300'
            }
          >
            <img src={require('../images/nursery.png')} alt='nursery' />
            <p>Nursery</p>
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? 'border-b-4 pb-3 px-24 text-[#21409A] border-[#21409A]'
                : ' text-black border-b-4 px-24 pb-3 border-gray-300'
            }
          >
            <img src={require('../images/euro_junior.png')} alt='euro_junior' />
            <p>EuroJunior</p>
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? 'border-b-4 pb-3 px-24 text-[#21409A] border-[#21409A]'
                : ' text-black border-b-4 px-24 pb-3 border-gray-300'
            }
          >
            <img src={require('../images/euro_senior.png')} alt='euro_senior' />
            <p>EuroSenior</p>
          </Tab>
        </Tab.List>
        <Tab.Panels>
          {/* Playgroup */}
          <Tab.Panel>
            <div className='grid gap-3 mt-10 space-y-5 sm:grid-cols-3 lg:grid-cols-6'>
              {/* 1st grid */}
              <div className='p-5'>
                <p className='text-xl text-gray-500'>2 - 3.5 Years :</p>
                <p className='text-2xl text-[#21409A] font-bold'>Playgroup</p>
              </div>

              {/* 2nd grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>
                  Hands-on discovery and exploration with exclusive EuroKids
                  kits
                </p>
              </div>

              {/* 3rd grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Developing language skills</p>
              </div>

              {/* 4th grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Encouraging learning of new age skills</p>
              </div>

              {/* 5th grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Engaging children physically, emotionally and socially</p>
              </div>

              {/* 6th grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Shaping scientific thought in children</p>
              </div>
            </div>
          </Tab.Panel>

          {/* Nursery */}
          <Tab.Panel>
            <div className='grid gap-3 mt-10 space-y-5 sm:grid-cols-3 lg:space-y-0 lg:grid-cols-5'>
              {/* 1st grid */}
              <div className='p-5'>
                <p className='text-xl text-gray-500'>3 - 4.5 Years :</p>
                <p className='text-2xl text-[#21409A] font-bold'>Playgroup</p>
              </div>

              {/* 2nd grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Imparts essential life skills</p>
              </div>

              {/* 3rd grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>
                  Enhances learning and interaction through fun with exclusive
                  EuroKids kits
                </p>
              </div>

              {/* 4th grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Cultivates creativity & Develops curiosity</p>
              </div>

              {/* 5th grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Improves imagination through Art and Music</p>
              </div>
            </div>
          </Tab.Panel>

          {/* Euro Junior */}
          <Tab.Panel>
            <div className='grid gap-3 p-4 mt-10 space-y-5 sm:grid-cols-2 lg:grid-cols-4'>
              {/* 1st grid */}
              <div className='p-5'>
                <p className='text-xl text-gray-500'>4 - 5.5 Years :</p>
                <p className='text-2xl text-[#21409A] font-bold'>Playgroup</p>
              </div>

              {/* 2nd grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Special activities for developing language skills</p>
              </div>

              {/* 3rd grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>
                  Age appropriate learning with exclusive EuroKids learning
                  tools
                </p>
              </div>

              {/* 4th grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>
                  Encourages children to express their ideas, thoughts and
                  feelings Allows children to grow at their own pace
                </p>
              </div>
            </div>
          </Tab.Panel>

          {/* Euro Senior */}
          <Tab.Panel>
            <div className='grid gap-3 mt-10 space-y-4 sm:grid-cols-3 lg:grid-cols-5 lg:space-y-0'>
              {/* 1st grid */}
              <div className='p-5'>
                <p className='text-xl text-gray-500'>5 - 6.5 Years :</p>
                <p className='text-2xl text-[#21409A] font-bold'>Playgroup</p>
              </div>

              {/* 2nd grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Building & enhancing vocabulary</p>
              </div>

              {/* 3rd grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Developing attention span</p>
              </div>

              {/* 4th grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>
                  Innovative methods to enhance reading & writing with exclusive
                  EuroKids Kits
                </p>
              </div>

              {/* 5th grid */}
              <div className='bg-[#21409A] rounded-2xl p-5 text-white text-2xl'>
                <div className='relative'>
                  <img
                    src={require('../images/bars_tabs.png')}
                    alt='bars_tabs'
                    className='absolute left-0 right-0 mx-auto -top-8'
                  />
                </div>
                <p>Preparing children for primary school</p>
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

const Partners = () => {
  return (
    <div className='bg-[#FECA02] p-5'>
      <div className='grid py-6 mx-auto md:grid-cols-2 max-w-7xl'>
        <div className='space-y-5'>
          <img
            src={require('../images/eunoia.png')}
            alt='eunoia'
            className='w-auto h-12'
          />
          <p className='text-xl text-gray-700'>
            At the heart of our preschooling child-first ideology is Eunoia - a
            mindful curriculum that enables holistic development of your child’s
            mind, body and soul
          </p>
        </div>
        <div className='grid grid-cols-4 gap-5'>
          {partners.map((item) => (
            <div
              className='p-5 bg-white border border-blue-600 rounded'
              key={item.id}
            >
              <img src={item.img} alt={item.id} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Awarded = () => {
  return (
    <div className='bg-[#545FAD]'>
      <div className='mx-auto max-w-7xl'>
        <p className='text-3xl font-bold text-center text-white'>
          India’s Most Awarded Pre-School Group
        </p>
        <div className='grid px-5 sm:grid-cols-2 lg:grid-cols-4 sm:px-0'>
          <div className='col-span-3 py-16 space-y-8'>
            <div className='grid grid-cols-3 gap-10'>
              <div>
                <p className='text-xl text-center text-white'>
                  Times Education Icon in the Top Preschool Category
                </p>
              </div>
              <div>
                <p className='text-xl text-center text-white'>
                  Most Trusted Brand
                </p>
              </div>
              <div>
                <p className='text-xl text-center text-white'>
                  Best Early Education <br /> Innovation Curriculum
                </p>
              </div>
            </div>

            <div className='grid grid-cols-3 gap-10'>
              <div>
                <p className='text-xl text-center text-white'>
                  Preschool Franchisor of the year
                </p>
              </div>
              <div>
                <p className='text-xl text-center text-white'>
                  Best Companies to Work for 2015
                </p>
              </div>
              <div>
                <p className='text-xl text-center text-white'>
                  Best Educations Brand
                </p>
              </div>
            </div>

            <div className='grid grid-cols-3 gap-10'>
              <div>
                <p className='text-xl text-center text-white'>
                  India Education Awards 2017
                </p>
              </div>
              <div>
                <p className='text-xl text-center text-white'>
                  Brand Academy Education Excellence Awards
                </p>
              </div>
              <div>
                <p className='text-xl text-center text-white'>
                  India Education <br /> Awards 2016
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={require('../images/rabbit_3.png')}
              className='rabbit_3'
              alt='rabbit 3'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// const IndiaTrust = () => {
//   return (
//     <div className="bg-[#D6FDE9]" id="testimonial">
//       <p className="mx-auto text-center text-[#545FAD] text-xl font-bold max-w-[300px]">
//         Parents across India trust EuroKids Pre-School Expertise
//       </p>
//       <div className="grid max-w-4xl grid-cols-3 py-8 mx-auto gap-14">
//         <div className="p-5 bg-white rounded-tr-3xl rounded-bl-3xl">
//           <div className="bg-[#0E40A0] h-32 w-full"></div>
//         </div>
//         <div className="p-5 bg-white rounded-tr-3xl rounded-bl-3xl">
//           <div className="bg-[#0E40A0] h-32 w-full"></div>
//         </div>
//         <div className="p-5 bg-white rounded-tr-3xl rounded-bl-3xl">
//           <div className="bg-[#0E40A0] h-32 w-full"></div>
//         </div>
//       </div>
//     </div>
//   );
// };

export const Footer = () => {
  return (
    <>
      <div className='bg-[#D6FDE9] pb-16 px-5'>
        <div
          className='grid mx-auto md:grid-cols-2 lg:grid-cols-4 max-w-7xl'
          id='contactUs'
        >
          <div className='mb-5'>
            <img src={require('../images/footer_logo.png')} alt='logo' />
          </div>
          <div className='flex flex-col space-y-2 text-[#0E40A0] mb-5'>
            <a href='#programs'>Nursery</a>
            <a href='#programs'>Playgroup</a>
            <a href='#programs'>EuroJunior</a>
            <a href='#programs'>EuroSenior</a>
          </div>
          <div className='flex flex-col text-[#0E40A0] mb-5'>
            <a
              href='tel:7036811111'
              className='flex items-center text-xl font-bold'
            >
              <PhoneIcon className='w-4 h-4' />
              &nbsp;<span>7</span>
              &nbsp;<span>0</span>
              &nbsp;<span>3</span>
              &nbsp;<span>6</span>
              &nbsp;<span>8</span>
              &nbsp; &nbsp; &nbsp;<span>1</span>
              &nbsp;<span>1</span>
              &nbsp;<span>1</span>
              &nbsp;<span>1</span>
              &nbsp;<span>1</span>
            </a>
            <a
              href='mailto:ekhimayathnagar@gmail.com'
              className='flex items-center'
            >
              <MailIcon className='w-4 h-4' />
              &nbsp;ekhimayathnagar@gmail.com
            </a>
          </div>
          <div className='text-[#0E40A0] mb-5'>
            3-6-451, Lane No. 5, Himayath Nagar, Hyderabad, Telangana 500029
          </div>
        </div>
      </div>
      <div className='container flex items-center justify-between mx-auto -mt-16'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='58.619'
          height='60.805'
          viewBox='0 0 58.619 60.805'
        >
          <g
            id='Group_38'
            data-name='Group 38'
            transform='translate(-918.258 -720.68)'
          >
            <path
              id='Path_46'
              data-name='Path 46'
              d='M938.442,758.584s4.877,6.06,4.434,23.647c0,0,3.695.739,4.581-1.034,0,0,.147-20.248-6.5-23.943Z'
              transform='translate(-3.282 -5.946)'
              fill='#2c8c7a'
              fill-rule='evenodd'
            />
            <path
              id='Path_47'
              data-name='Path 47'
              d='M942.122,757.107s-10.355,7.039-19.831,1.943c-11.046-5.117,3.057-25.245,12.93-25.156,0,0,7,.009,9,.714,0,0,8.7,1.867,7.039,10.355,0,0-1.062,6.39-9.135,12.144'
              transform='translate(0 -2.148)'
              fill='#de004e'
              fill-rule='evenodd'
            />
            <path
              id='Path_48'
              data-name='Path 48'
              d='M926.072,751.437s3.572,1.414,5.751-3.58c-.774-3.78-6-1.747-6.778,1.138a2.151,2.151,0,0,0,1.027,2.442'
              transform='translate(-1.091 -4.083)'
              fill='#ffbb0c'
              fill-rule='evenodd'
            />
            <path
              id='Path_49'
              data-name='Path 49'
              d='M943.185,745.706s2.468.976,3.974-2.473c-.534-2.612-4.147-1.208-4.683.786a1.487,1.487,0,0,0,.709,1.687'
              transform='translate(-3.929 -3.435)'
              fill='#ffbb0c'
              fill-rule='evenodd'
            />
            <path
              id='Path_50'
              data-name='Path 50'
              d='M934.862,744.453s2.192.868,3.527-2.195c-.474-2.318-3.68-1.071-4.156.7a1.318,1.318,0,0,0,.629,1.5'
              transform='translate(-2.59 -3.303)'
              fill='#ffbb0c'
              fill-rule='evenodd'
            />
            <path
              id='Path_51'
              data-name='Path 51'
              d='M940.776,736.229c-.474-2.318-3.68-1.071-4.156.7.2,1.577,4.1.873,4.156-.7'
              transform='translate(-2.986 -2.322)'
              fill='#ffbb0c'
              fill-rule='evenodd'
            />
            <path
              id='Path_52'
              data-name='Path 52'
              d='M962.1,746.469s-4.517,5.632-4.074,31.348l5.321-.3a63.472,63.472,0,0,1,1.625-29.854Z'
              transform='translate(-6.461 -4.193)'
              fill='#2c8c7a'
              fill-rule='evenodd'
            />
            <path
              id='Path_53'
              data-name='Path 53'
              d='M955.015,789.118s-5.173.3-4.582-5.32,13.893-6.8,13.893-6.8,11.528-1.625,11.38,7.833c0,0,.443,6.946-20.692,4.286'
              transform='translate(-5.224 -9.14)'
              fill='#267a5b'
              fill-rule='evenodd'
            />
            <path
              id='Path_54'
              data-name='Path 54'
              d='M962.4,745.4s-12.267-2.513-15.223-12.858c-4.035-11.485,20.226-15.414,27.047-8.276,0,0,4.877,5.024,5.764,6.946,0,0,4.73,7.538-2.512,12.266,0,0-5.321,3.7-15.075,1.922'
              transform='translate(-4.629)'
              fill='#ffbb0c'
              fill-rule='evenodd'
            />
            <path
              id='Path_55'
              data-name='Path 55'
              d='M957.671,728.632s1.478,3.546,6.577,1.625c2.17-3.19-2.933-5.52-5.542-4.064a2.149,2.149,0,0,0-1.035,2.439'
              transform='translate(-6.401 -0.824)'
              fill='#de004e'
              fill-rule='evenodd'
            />
            <path
              id='Path_56'
              data-name='Path 56'
              d='M973.912,736.789s1.021,2.45,4.543,1.123c1.5-2.2-2.027-3.813-3.829-2.807a1.484,1.484,0,0,0-.714,1.684'
              transform='translate(-9.044 -2.295)'
              fill='#de004e'
              fill-rule='evenodd'
            />
            <path
              id='Path_57'
              data-name='Path 57'
              d='M969.056,729.923s.906,2.176,4.033,1c1.331-1.957-1.8-3.386-3.4-2.492a1.316,1.316,0,0,0-.634,1.495'
              transform='translate(-8.255 -1.216)'
              fill='#de004e'
              fill-rule='evenodd'
            />
            <path
              id='Path_58'
              data-name='Path 58'
              d='M979.159,728.419c1.331-1.956-1.8-3.385-3.4-2.492-.99,1.244,2.233,3.548,3.4,2.492'
              transform='translate(-9.319 -0.809)'
              fill='#de004e'
              fill-rule='evenodd'
            />
            <path
              id='Path_59'
              data-name='Path 59'
              d='M972.135,786.8a3.481,3.481,0,0,1,5.05,3.475c-2.647,4.551-8.577-1.527-5.05-3.475'
              transform='translate(-8.583 -10.686)'
              fill='#6cb7b1'
              fill-rule='evenodd'
            />
            <path
              id='Path_60'
              data-name='Path 60'
              d='M966.935,788.944a2.439,2.439,0,0,1,3.539,2.436c-1.855,3.189-6.011-1.07-3.539-2.436'
              transform='translate(-7.79 -11.054)'
              fill='#6cb7b1'
              fill-rule='evenodd'
            />
          </g>
        </svg>
        <img src={require('../images/svg2.png')} alt='svg2' />
        <img src={require('../images/svg.png')} alt='svg' />
      </div>
      <div className='bg-[#0E40A0] py-3'>
        <div className='container flex items-center justify-between mx-auto text-gray-200'>
          <p>© 2022 All Rights Reserved by EuroKids Himayath Nagar</p>
          <p>
            Powered by{' '}
            <a href='https://broaddcast.com/' target='_blank' rel='noreferrer'>
              BroaddCast Business Solutions LLP
            </a>{' '}
          </p>
        </div>
      </div>
    </>
  );
};

export default Home;

import { useState } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Footer } from './Home';

const FinalTq = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ name: '', mobile: '' });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       'https://eurokids-1aci.onrender.com/enquiryRequest',
  //       formData
  //     );
  //     console.log('Form submitted successfully:', response.data);
  //     toast.success('Enquiry submitted successfully');
  //     navigate('/refer');
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //     toast.error('Something went wrong!');
  //   }
  // };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className='bg-[#545FAD] '>
      <ToastContainer />
      <div className='container mx-auto p-2'>
        <div className='pt-6 border-red-900'>
          <div className='text-3xl md:text-5xl font-bold text-white'>
            Thank You!
          </div>
          <div className='text-lg md:text-xl text-white font-extralight'>
            Our preschool expert will connect with you soon.
          </div>
          <div className='pt-3 mt-4'>
            <h2 className='text-lg md:text-xl font-extrabold mb-4 text-white'>
              Would you like to refer a parent to EuroKids?
            </h2>
            <div className='text-yellow-200'>
              Thank you for referring a friend to EuroKids
            </div>
            {/* <form onSubmit={handleSubmit}>
              <div className='flex flex-wrap -mx-2'>
                <div className='w-full sm:w-1/2 px-2 mb-4'>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    className='mt-1 p-2 border rounded-full w-full'
                    placeholder='Name'
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className='w-full sm:w-1/2 px-2 mb-4'>
                  <input
                    type='text'
                    id='mobile'
                    name='mobile'
                    className='mt-1 p-2 border rounded-full w-full'
                    placeholder='Mobile Number'
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='bg-[#D6FDE9] text-[#0F40A0] py-3 md:py-4 px-6 rounded-full text-md md:text-lg hover:bg-[#a5c3b4] font-extralight shadow-md'
                >
                  Submit
                </button>
              </div>
            </form> */}
            <div className='mx-auto p-2 '>
              <div className='flex flex-col'>
                <div className='mb-4 pt-6 md:pt-10'>
                  <h2 className='text-lg md:text-xl font-bold text-white'>
                    Connect with us
                  </h2>
                </div>
                <div className='flex space-x-4'>
                  <a href='#' className='text-[#D6FDE9] hover:text-[#a5c3b4]'>
                    <FaFacebook className='h-7 w-7 md:h-8 md:w-8' />
                  </a>
                  <a href='#' className='text-[#D6FDE9] hover:text-[#a5c3b4]'>
                    <FaInstagram className='h-7 w-7 md:h-8 md:w-8' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={require('../images/4.png')} className='w-full' alt='1' />
      <Footer />
    </div>
  );
};

export default FinalTq;


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyBhAxidDM45Uz0so2bQRHCBTEVnh7Qb7HA",
  authDomain: "eurokids-8ca17.firebaseapp.com",
  projectId: "eurokids-8ca17",
  storageBucket: "eurokids-8ca17.appspot.com",
  messagingSenderId: "959024454800",
  appId: "1:959024454800:web:193fe43a1863b00fb18c15",
  measurementId: "G-04BXSK4L8T",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

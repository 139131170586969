import React from 'react'
import Home from './Home'
import Mobile from '../Mobile'

const Hompage = () => {
  return (
    <div>
        <div className='hidden md:block'><Home /></div>
        <div className=' md:hidden'><Mobile /></div>
    </div>
  )
}

export default Hompage